<template>
  <div class="modal modal-store" :class="{ open: isOpen }">
    <div class="modal__topbar" :class="{ open: isOpen }">
      <button @click="isOpen = false" class="button modal__close" type="button">
        <i class="icon icon-close"></i>
      </button>
    </div>
    <slot> </slot>
  </div>
</template>

<script>
export default {
  data: () => ({
    body: document.querySelector('body'),
    overlay: document.querySelector('.overlay')
  }),
  props: {
    modal: Boolean
  },

  mounted () {
    this.overlay.removeEventListener('click', () => (this.isOpen = false))
    this.overlay.addEventListener('click', () => (this.isOpen = false))
  },

  computed: {
    isOpen: {
      get () {
        return this.modal
      },
      set (val) {
        this.$emit('changeModal', val)
      }
    }
  },
  watch: {
    modal (val) {
      if (val) {
        this.body.classList.add('modal__openned')
        this.overlay.classList.add('show')
      } else {
        this.body.classList.remove('modal__openned')
        this.overlay.classList.remove('show')
      }
    }
  }
}
</script>
